import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'IBASE.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified InterBase/Firebird client library.`,
        valid:
            <>
                Any valid InterBase client library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"gds32.dll;fbclient.dll"</code></li>
                <li>Linux - {' '}<code>"libgds.so:libfbclient.so"</code></li>
            </ul>
    },
    {
        name: 'isc_dpb_lc_ctype',
        scope: 'connection',
        description: `Specifies the character set to be utilized. For more information see InterBase documentation.`,
        valid:
            <>
                String containing character set name, see InterBase documentation
            </>,
        defaultValue:
            <>
                see InterBase documentation
            </>
    },
    {
        name: 'isc_dpb_sql_role_name',
        scope: 'connection',
        description: `Declares role name that should used for connection. For more information see InterBase documentation.`,
        valid:
            <>
                see InterBase documentation
            </>,
        defaultValue:
            <>
                see InterBase documentation
            </>
    },
    {
        name: 'isc_dpb_num_buffers',
        scope: 'connection',
        description: `Declares the number of database cache buffers to allocate for use with the database.
                      For more information see InterBase documentation.`,
        valid:
            <>
                String that represents numeric values in 10-255 range, see InterBase documentation
            </>,
        defaultValue:
            <>
                <code>"75"</code>, see InterBase documentation
            </>
    },
    {
        name: 'CommitRetaining',
        scope: 'connection',
        description:
            <>
                <p>
                    Determines whether SQLAPI++ calls {' '}<CodeBlend>isc_commit_transaction()</CodeBlend>{' '}
                    or {' '}<CodeBlend>isc_commit_retaining()</CodeBlend> function
                    to commit transactions.
                </p>
                <p>
                    <CodeBlend>isc_commit_transaction()</CodeBlend>{' '} writes transaction
                    changes permanently to database, closes the result set associated with the
                    transaction, and frees system resources assigned to the transaction for other uses.
                    Active result sets are not preserved.
                </p>
                <p>
                    <CodeBlend>isc_commit_retaining()</CodeBlend>{' '} writes all
                    pending changes to the database, ends the current transaction without closing its record
                    stream and cursors and without freeing its system resources, then starts a new
                    transaction and assigns the existing record streams and system resources to the
                    new transaction. Active result sets are preserved.
                </p>
                <p>
                    For more information see InterBase documentation for
                    {' '}<CodeBlend>isc_commit_retaining()</CodeBlend>{' '} and
                    {' '}<CodeBlend>isc_commit_transaction()</CodeBlend>{' '} functions.
                </p>
            </>,
        valid:
            <ul>
                <li>
                    <code>"true"</code>{' '} &ndash; to have SQLAPI++
                    call {' '}<CodeBlend>isc_commit_retaining()</CodeBlend>
                </li>
                <li>
                    <code>"false"</code>{' '} &ndash; to have SQLAPI++
                    call {' '}<CodeBlend>isc_commit_transaction()</CodeBlend>
                </li>
            </ul>,
        defaultValue:
            <>
                <code>"false"</code>{' '} &ndash; SQLAPI++
                uses {' '}<CodeBlend>isc_commit_transaction()</CodeBlend>{' '} function by default
            </>
    },
    {
        name: 'TPB_LockResolution',
        scope: 'connection',
        description: `Describes what happens if a transaction encounters an access conflict during
                      a write operation (update and delete operations on existing rows).`,
        valid:
            <ul>
                <li>
                    <code>"isc_tpb_wait"</code>{' '} &ndash; specifies that the transaction should wait until
                    locked resources are released; once the resources are released, the transaction
                    retries its operation
                </li>
                <li>
                    <code>"isc_tpb_nowait"</code>{' '} &ndash; specifies that the transaction should
                    return a lock conflict error without waiting for locks to be released
                </li>
            </ul>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change InterBase settings for this option.
                See InterBase documentation for details.
            </>
    },
    {
        name: 'TPB_AccessMode',
        scope: 'connection',
        description: `Describes the actions a transaction can perform against a table.`,
        valid:
            <ul>
                <li>
                    <code>"isc_tpb_write"</code>{' '} &ndash; enables a transaction to read data from a table
                    and write data to it
                </li>
                <li>
                    <code>"isc_tpb_read"</code>{' '} &ndash; restricts table access to readonly
                </li>
            </ul>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change InterBase settings for this option.
                See InterBase documentation for details.
            </>
    },
    {
        name: 'SQLDialect',
        scope: 'command',
        description: `Declares SQL Dialect for database access.`,
        valid:
            <>
                see InterBase documentation
            </>,
        defaultValue:
            <code>
                "3"
            </code>
    }
];

export {
    options
};
