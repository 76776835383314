import React from "react";

import ServerGuide, {
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/ibase";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ Library requires InterBase client API version 5.x or higher. All Firebird versions are supported as
        well.
    </Callout>
);
const sDBString = () => (
    <ul>
        <li>For local InterBase databases, this can be a file name</li>
        <li>
            To connect to an InterBase database on a remote server using TCP/IP the syntax is
            {' '}<code>{`<server_name>[/<port_number>]:<filename>`}</code>
        </li>
        <li>
            To connect to an InterBase database on a remote server using NetBEUI, the syntax is
            {' '}<code>{`\\\\<server_name>\\<filename>`}</code>
        </li>
        <li>
            To connect to an InterBase database on a remote server using SPX, the syntax is
            {' '}<code>{`<server_name>@<filename>`}</code>
        </li>
    </ul>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParamNotes = () => (
    <>
        <p>
            If InterBase stored procedure returns only one row of output parameters
            (the most common case), you can get output parameters' values immediately after
            calling {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} using appropriate
            {' '}<ApiLink>SAParam</ApiLink>{' '} objects.
        </p>
        <p>
            InterBase stored procedures can also return several rows of output
            parameters. In this case you should perform a select from a procedure to return
            them as result set.
        </p>
    </>
);

const CancelQueryNotes = () => (
    <p>
        InterBase does not support cancelling queries. So if you call
        {' '}<ApiLink>SACommand::Cancel</ApiLink>{' '} it will have no effect.
    </p>
);

const Page = () => (
    <ServerGuide server="InterBase / Firebird"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParamNotes}
                 cancelCommandNotes={CancelQueryNotes}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="InterBase / Firebird"
                                                                        cppHeader="ibAPI.h"
                                                                        apiClass="ibAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="InterBase / Firebird"
                                                                                handleClass="ibConnectionHandles"
                                                                                cppHeader="ibAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="InterBase / Firebird"
                                                                          handleClass="ibCommandHandles"
                                                                          cppHeader="ibAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="InterBase / Firebird"/>}
    />
);

export default Page;
